/* General CSS */
.App{
    text-align: justify;
    page-break-inside: auto;
}

body {
    background-color: #865c0db4;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    font-family: monospace;
}



/* Nav-bar CSS */
.navBar {
    top: 0;
    position: fixed;
    display: flex;
    background-color: #865c0d;
    align-items: center;
    justify-content: space-around;
    color: rgb(189, 162, 113);
    border-radius: 12.5px;
    border: solid 2px;
    padding: 5px;
    position: fixed; 
    left: 0; 
    z-index: 9999; 
    width: 100%; 
    height: 60px;
}


.signoutButton {
    border-radius: 3px;
    background-color: whitesmoke;
    cursor: pointer;
    font-weight: bold;
    height: 30px;
    margin-top:5px
}

.Link {
    color: lightgoldenrodyellow;
    margin: 15px;
    font-size: 17.5px;
}


/* About CSS */
.pic {
    display: flex;
    justify-content: space-evenly;
}

.qoute {
    margin-top: 100px;
}

.navImage {
    height:350px;
    width: 350px;
    border-radius: 10px;


}

.alphaAboutDiv {
    margin-top: 40px;
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
    border-radius: 20px;
    background-color: rgba(204, 188, 169, 0.966);
    text-align: center;
    border-style: solid;
    border-color: rgb(189, 162, 113);
    border-width: 3px;
}

.genericDiv {
    background-color: #865c0db4;;
    padding: 10px;
    margin: 30px;
    border-radius: 12.5px;
    border-style: solid;
    border-color: lightsteelblue;
    text-align: left;
}

.qouteDiv{
    text-align: left;
    margin-left: 50px;
    margin-right: 50px;
    padding-left: 50px;
    margin-top: 30px;
    border-width: 3px;
}
.para {
    background-color: rgb(233, 214, 180);
    border-radius: 12.5px;
    padding: 5px;
}

/* Create Post CSS */

.createPostPage {
    margin-top: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    background-color: #865c0d;;
    text-align: center;
    border-style: solid;
    border-color: #865c0d;
    border-width: 3px;
    position: fixed;
    width: 88.28%;

}

.inputGP {
    background-color: #865c0db4;;
    padding: 10px;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 12.5px;
    border-style: solid;
    border-color: lightsteelblue;
    text-align: left;
}

.textArea {
    border-color:#865c0d;
    border-width: 3px;
    max-width: 600px;
}

.accountPage {
    padding-top: 330px;
    display: flex;
}

.listDiv {
    width: 50%;
    margin: 10px;
    border: dotted;
    border-width: 3px;
    padding: 5px;
    padding-right:15px;
    height: 300px;
    overflow-y: scroll;
    border-radius: 5px;
}

.buttonDiv {
    margin-left: 10px;
    height: 30px;
    margin-top: 10px;
}
/* Blog CSS*/

.forumPage {
    margin-top: 40px;
    padding-top: 2px; 
    padding-bottom: 5px;
    border-radius: 20px;
    background-color: rgba(204, 188, 169, 0.966);
    text-align: left;
    border-style: solid;
    border-color: rgb(233, 214, 180);
    border-width: 3px;
    width: 100%;
    min-height: 600px;
}

.post{
    background-color: #7e6330;;
    padding: 10px;
    margin: 15px;
    border-radius: 12.5px;
}

.forumTitle {
    margin-left: 50px;
}

.forumDiv {
    background-color: rgb(233, 214, 180);
    padding: 10px;
    border-radius: 12.5px;
    border-style: solid;
    border-color: rgb(218, 218, 177);
    justify-content: space-evenly;
}

.forumHead {
    text-align: center;
}

.posts {
    width:65%;
    border: dotted;
    border-radius: 10px;
    border-color: #865c0d;
    border-width: 3px;
    margin-left: 10px;
    height: 600px;
    overflow-y: scroll;
}

.forumSides {
    box-sizing: content-box;
    position: fixed;
    border: solid;
    left: 65%;
    width: 28%;
    margin-right: 10px;
    border-color: #865c0d;
    border-width: 3px;
    border-radius: 5px;
    grid-row: auto;
}

.parent {
    display: flex;
}

.sidesHead {
    background-color: #865c0db4;
    margin: 5px;
    margin-bottom:0px;
    padding: 2px;
    text-align: center;
}

.sidesPoints {
    margin-bottom: 5px;
    background-color: rgb(233, 214, 180);
    border-bottom: dotted;
    border-width: 3px;
}

.parentTitleDiv {
    display: flex;
}

.optionDiv {
    margin: 10px;
    margin-top: 17px;
    margin-bottom:15px;
}

.spacebarDiv {
    margin: 10px;
    margin-top: 17px;
    margin-bottom:15px;
    border-radius: 10px;
}

.filterHeader {
    margin-left: 20px;
}

.accountDiv {
    left:100px;
    display:flex;
    border:solid;
    justify-content: space-evenly;
    border-radius: 10px;
}

.commentSection {
    padding-left:20px;
}

.commentBox {
    border-bottom: dotted;
    padding-bottom: 5px;
    border-color: #865c0d;
    border-width: 2px;
}

.commentsDiv {
    display: flex;
    margin:1px;
    min-height: 30px;
}

.commentButton {
    max-height: 30px;
}

